class ReportStatusList {
  $element: any;
  onSelect: Function;
  constructor ($element) {
    Object.defineProperties(this, {
      $element: { value: $element }
    });
  }

  select(status): void {
    this.onSelect({ status });
    this.hide();
  }

  hide(): void {
    this.$element.remove();
  }
}

window.app.component('reportStatusList', {
  template: require('scripts/components/time-trackings/report-status/report-status-list.html'),
  controller: ['$element', ReportStatusList],
  bindings: {
    statusList: '<',
    selected:   '=',
    onSelect:   '&'
  }
});

class ReportStatus {
  $compile: any;
  $scope:   any;

  ngModel:  any;
  disabled: boolean;
  constructor ($compile, $scope) {
    Object.defineProperties(this, {
      $compile: { value: $compile },
      $scope:   { value: $scope   },
    });
  }

  showSelect(): void {
    let el = angular.element("<report-status-list status-list='$ctrl.statusList' selected='$ctrl.ngModel' on-select='$ctrl.select(status)'></report-status-list>");
    angular.element(document).find('ng-view').append(this.$compile(el)(this.$scope));
  }

  select(status): void {
    this.ngModel = status;
  }
}

window.app.component('reportStatus', {
  template: require('scripts/components/time-trackings/report-status/report-status.html'),
  controller: ['$compile', '$scope', ReportStatus],
  bindings: {
    ngModel:    '=',
    statusList: '<',
    disabled:   '<'
  }
});
