class PhotoDocumentRootItems {
  $location:    any;
  $scope:       any;
  api:          any;
  appFactory:   any;
  errFactory:   any;
  notification: any;

  live:    boolean;
  preprod: boolean;

  isOpened:            boolean;
  photoDocumentsCount: number;
  constructor ($location, $scope, API, AppFactory, Config, ErrFactory, Notification) {
    Object.defineProperties(this, {
      $location:    { value: $location    },
      $scope:       { value: $scope       },
      api:          { value: API          },
      appFactory:   { value: AppFactory   },
      errFactory:   { value: ErrFactory   },
      notification: { value: Notification }
    });

    this.live    = Config.hostName && Config.hostName === 'live';
    this.preprod = Config.hostName && Config.hostName === 'preprod';

    if (this.isDevice() || (!this.live && !this.preprod)) this.fetchPhotoDocuments();
  }

  isDevice(): boolean {
    return !!window.cordova;
  }

  goToNewPhotoDocument(event, to): void {
    if ((this.live || this.preprod) && !window.cordova) return this.notification.alert({ title: 'note', desc: 'timeTrackings.webviewerDisabled' });
    this.changeLocation(event, to);
  }

  changeLocation(event, to) {
    if (!this.appFactory.isNetwork()) return new this.errFactory.NoInternetConnectivity().notify();
    event.stopPropagation();
    this.$location.path(to);
  }

  private fetchPhotoDocuments(): Promise<void> {
    return this.api.getPhotoDocumentsList()
    .then(res => {
      if (res) {
        this.photoDocumentsCount = res.length;
        this.$scope.$apply();
      }
    })
    .catch(err => {
      if (err instanceof this.errFactory.NoInternetConnectivity) console.error(err);
      else if (err instanceof this.errFactory) err.notify();
      else console.error(err);
    })
  }

  toggle(): void {
    this.isOpened ? this.close(): this.open();
  }

  private open(): void {
    let folder   = document.getElementsByTagName('pd-root-items')[0].getElementsByClassName('animate-height')[0];
    let height = [...<any>folder.children].filter(el => el.localName === 'nested-list-item').reduce((sum, val) => sum = sum + +val?.children[0]?.offsetHeight, 0);
    folder.style.height = height + 'px';
    this.isOpened = true;
  }

  private close(): void {
    let folder   = document.getElementsByTagName('pd-root-items')[0].getElementsByClassName('animate-height')[0];
    folder.style.height = '0px';
    this.isOpened = false;
  }

}

window.app.component('pdRootItems', {
  template: require('scripts/components/time-trackings/photo-document/root-items/pd-root-items.html'),
  controller: ['$location', '$scope', 'API', 'AppFactory', 'Config', 'ErrFactory', 'Notification', PhotoDocumentRootItems]
});
